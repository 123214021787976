
import { PropType, defineComponent, watch, ref, onBeforeMount } from "vue";
import useAlert from "@/composables/Alert";
import { Modal } from "bootstrap";
import { ITrainingData, STATUS_OPTIONS, TYPE_STATUS_OPTIONS } from "../ListPage.vue";
import axios, { AxiosError } from "axios";
import {
  CampHeader,
  CampTable,
  CampTableTd,
  CampTableTh,
  CampEmptyListFeedbackV2
} from '@/components';
import SkeletonComponent from '@/components/SkeletonComponent.vue';
import moment from 'moment';

export type TReportCard = {
  points: number;
  questions: {
    id: number;
    title: string;
    resposta: string;
    status: string;
    show?: boolean;
  }[]
}

export type TAnswer = {
  id: number;
  title: string;
  resposta: string;
  status: string;
  show?: boolean;
}

export interface ICompetitorTraining {
  id: number | string | null,
  id_competitor: number | string | null,
  name: string,
  entity_name: string,
  id_training: number | string | null,
  satus: TYPE_STATUS_OPTIONS,
  updated_at: string,
  trainingGroup?: string,
  answers: TAnswer[] | string | null,
  points: number | null,
  cpf: string | null
}

interface ICompetitorTrainingListResponse {
  data: {
    data: ICompetitorTraining[],
    error: boolean,
    message: string,
    errorCode: string
  }
}

export default defineComponent({
  name: "ModalCompetitorList",
  components: {
    SkeletonComponent,
    CampHeader,
    CampTable,
    CampTableTd,
    CampTableTh,
    CampEmptyListFeedbackV2
  },
  props: {
    toggle: {
      type: Boolean,
      default: false
    },
    status: {
      type: String as PropType<TYPE_STATUS_OPTIONS>,
      required: true
    },
    training: {
      type: Object as PropType<ITrainingData | null>,
      required: true
    }
  },
  setup(props, { emit }) {
    // Variables
    const { showTimeAlert } = useAlert()
    let auxModal
    const UUID = "9d1250a5-c2b0-41c5-b7a9-9e01ec8b6bfb"
    const isLoading = ref(true)
    /** Training */
    const competitorTrainingList = ref<ICompetitorTraining[] | null>(null)
    const maxScore = ref(0)
    /** Company */
    const companyList = ref<{ id: number | string | null, fantasy_name: string }[] | null>(null)
    /** Store */
    const storeList = ref<{ id: number | string | null, fantasy_name: string }[] | null>(null)

    // Functions
    const openModal = (id: string) => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    }

    async function handleCompetitorTrainingList(training: ITrainingData, status: TYPE_STATUS_OPTIONS) {
      try {
        isLoading.value = true
        competitorTrainingList.value = null

        if(!companyList.value || !storeList.value)
          return

        const response:ICompetitorTrainingListResponse = await axios.get(`/api/getAllInfoCompetitorTrainingListByIdTraining?id_training=${training.id}&status=${status}`)
        competitorTrainingList.value = response.data.data.map(el => ({ ...el, answers: typeof el.answers === "string" ? JSON.parse(el.answers) : el.answers }))
        maxScore.value = training.points
      } catch (error) {
        if(error instanceof AxiosError) {
          if(error.response) {
            showTimeAlert(error.response?.data.message, "error")
          } else {
            showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
          }
        } else {
          showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
        }
      } finally {
        isLoading.value = false
      }
    }

    const handleStatusColor = (status: TYPE_STATUS_OPTIONS) => {
      if(status === STATUS_OPTIONS.FAILED)
        return 'var(--cor_indicadores_4) !important'
      if(status === STATUS_OPTIONS.PASSED)
        return 'var(--cor_indicadores_1) !important'
    }

    const handleStatusText = (status: TYPE_STATUS_OPTIONS) => {
      if(status === STATUS_OPTIONS.FAILED)
        return 'Reprovado'
      if(status === STATUS_OPTIONS.PASSED)
        return 'Aprovado'
    }

    const handleCompetitorTrainingInfo = (competitorTraining: ICompetitorTraining) => emit("getCompetitorTrainingInfo", competitorTraining)

    // Life Cycles
    watch(() => props.toggle, () => openModal(`modal-competitor-list-${UUID}`))

    watch(() => { props.training, props.status }, () => props.training && handleCompetitorTrainingList(props.training, props.status), { deep: true })

    onBeforeMount(async() => {
      try {
        const [companyListResponse, storeListRponse] = await Promise.all([
          await axios.get(`/api/getCompanyList`),
          await axios.get(`/api/getStoreList`)
        ]);

        companyList.value = companyListResponse.data.data
        storeList.value = storeListRponse.data.data
      } catch (error) {
        if(error instanceof AxiosError) {
          if(error.response) {
            showTimeAlert(error.response?.data.message, "error")
          } else {
            showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
          }
        } else {
          showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
        }
      }
    })

    return {
      UUID,
      STATUS_OPTIONS,
      isLoading,
      competitorTrainingList,
      maxScore,
      moment,
      handleStatusColor,
      handleStatusText,
      handleCompetitorTrainingInfo
    }
  }
})
