
import { defineComponent, watch, ref, PropType } from "vue";
import { Modal } from "bootstrap";
import { ICompetitorTraining, TAnswer } from "./ModalCompetitorList.vue";

export default defineComponent({
  name: "ModalReportCard",
  components: { },
  props: {
    toggle: {
      type: Boolean,
      default: false
    },
    competitorTraining: {
      type: Object as PropType<ICompetitorTraining | null>,
      required: true
    }
  },
  setup(props, { emit }) {
    // Variables
    let auxModal
    const UUID = "934ff556-30ab-467d-bcf8-f1e29a9bc1f3"
    const isModalVisible = ref(false);
    const answers = ref<TAnswer[] | null>(null)

    // Functions
    const openModal = (id: string) => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
      isModalVisible.value = !isModalVisible.value
    }

    const handleStatusColor = (status: string) => {
      if(status === "right")
        return 'var(--cor_indicadores_1) !important'
      if(status === "wrong")
        return 'var(--cor_indicadores_4) !important'
    }

    const handleStatusText = (status: string) => {
      if(status === "right")
        return 'Acertou'
      if(status === "wrong")
        return 'Errou'
    }

    // Life Cycles
    watch(() => props.toggle, () => {
      if(props.competitorTraining && typeof props.competitorTraining.answers === "object") {
        answers.value = props.competitorTraining.answers
        answers.value = answers.value!.map(el => ({ ...el, show: false }))
        openModal(`modal-report-card-${UUID}`)
      }
    })

    return {
      UUID,
      isModalVisible,
      answers,
      handleStatusColor,
      handleStatusText
    }
  }
})
