
import axios, { AxiosError } from 'axios';
import { defineComponent, ref, onMounted, watch } from 'vue';
import useAlert from "@/composables/Alert";
import {
  CampHeader,
  CampTable,
  CampTableTd,
  CampTableTh,
  CampEmptyListFeedbackV2
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import { useFilterMainStore } from '@/store/FilterMainStore';
import { useCompanyStore } from '@/store/CompanyStore';
import moment from 'moment';
import SkeletonComponent from '@/components/SkeletonComponent.vue';
import ModalCompetitorList, { ICompetitorTraining } from './Components/ModalCompetitorList.vue';
import ModalReportCard from './Components/ModalReportCard.vue';
import { CampModalMediaFromURL } from '@/components';

export type TYPE_STATUS_OPTIONS = 'closed' | 'passed' | 'failed'

export const STATUS_OPTIONS: {
  CLOSED: TYPE_STATUS_OPTIONS,
  PASSED: TYPE_STATUS_OPTIONS,
  FAILED: TYPE_STATUS_OPTIONS,
} = {
  CLOSED: 'closed',
  PASSED: 'passed',
  FAILED: 'failed',
}

export interface ITrainingData {
  id: number;
  title: string;
  description: string;
  url: null | string;
  initial_date: string;
  end_date: string;
  points: number;
  competitor_trainings: { competitor: { name: string } }[];
  training_groups: { id: number | null, id_company: number | null, id_store: number | null, id_training: number | null }[];
  competitor_trainings_count: number;
  failed_count: number;
  passed_count: number
}

interface ITrainingResponse {
  data: {
    data: ITrainingData[]
    error: boolean,
    message: string,
    errorCode: string
  }
}

type _TMediaModal = {
  URL: string;
  contentType: string;
  typeYoutubeToggle: boolean;
  typeVideoToggle: boolean;
  typeImgToggle: boolean;
  typePdfToggle: boolean;
}

export const _initMediaModal: _TMediaModal = {
  URL: "",
  contentType: "",
  typeYoutubeToggle: false,
  typeVideoToggle: false,
  typeImgToggle: false,
  typePdfToggle: false,
}

type TMediaModal = {
  URL: string;
  toggle: boolean
}
export const initMediaModal: TMediaModal = {
  URL: "",
  toggle: false
}

export default defineComponent({
  name: "TrainingList",
  components: {
    CampHeader,
    CampTable,
    CampTableTd,
    CampTableTh,
    CampEmptyListFeedbackV2,
    SkeletonComponent,
    ModalCompetitorList,
    ModalReportCard,
    CampModalMediaFromURL
  },
  setup() {
    const { showTimeAlert } = useAlert()
    const loaderStore = useLoaderStore()
    const filterMainStore = useFilterMainStore()
    const mediaModal = ref({ ...initMediaModal })
    const activeStatus = ref(true);
    // Company
    const companyStore = useCompanyStore()
    // Training
    const trainingList = ref<ITrainingData[] | null>(null)
    const trainingListRender = ref<ITrainingData[] | null>(null)
    const keywordSearch = ref("")
    const isLoading = ref(false)
    const trainingInfo = ref<ITrainingData | null>(null)
    const competitorTrainingInfo = ref<ICompetitorTraining | null>(null)
    // Modal Competitor List
    const competitorModalToggle = ref(false)
    const competitorListModalType = ref<TYPE_STATUS_OPTIONS>("passed")
    // Modal Report Card
    const reportCardModalToggle = ref(false)

    // Functions
    /** Fetch */
    async function getTrainingList() {
      if(!companyStore.getId)
        return
      keywordSearch.value = ""
      try {
        isLoading.value = true
        trainingListRender.value = null
        const url = `/api/getTrainingList?id_company=${companyStore.getId}&only_active=${activeStatus.value ? '1' : '0'}`
        const response:ITrainingResponse = await axios.get(url)
        const { data } = response.data
        trainingList.value = [ ...data ]
        trainingListRender.value = [ ...data ]
      } catch (error) {
        if(error instanceof AxiosError) {
          if(error.response) {
            showTimeAlert(error.response?.data.message, "error")
          }else {
            showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
          }
        } else {
          showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
        }
      } finally {
        loaderStore.close()
        isLoading.value = false
      }
    }

    function handleFilterObjectsByKeyword(): boolean {
      if(!trainingList.value)
        return true
      trainingListRender.value = trainingList.value.map(el => ({ ...el, checked: false })).filter((item) => {
        for (const key in item) {
          if (key !== 'checked') {
            const propValue = item[key];
            if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase())) {
              return true;
            }
            if (typeof propValue === 'number' && keywordSearch!.toString() === propValue.toString()) {
              return true;
            }
          }
        }
        return false;
      });
      return false
    }

    const handlePrintPoints = (value: number) => {
      let print: string | number = ''
      if (value > 0)
        print = `+ ${value}pt`
      else
        print = `${value}pt`
      if(Math.abs(value) > 1)
        print = `${print}s`
      return print
    }

    const handlePrintPointsStyle = (value: number) => {
      if(value === 0)
        return 'var(--cor_texto_1) !important'
      else if (value > 0)
        return 'var(--cor_indicadores_1) !important'
      else
        return 'var(--cor_indicadores_4) !important'
    }

    const handlePrintTrainingGroups1 = (array: { id: number | null; id_company: number | null; id_store: number | null; id_training: number | null; }[] ) => {
      if(Array.isArray(array)) {
        if(!array.length) {
          return 'Vendedores'
        }
        if(array.some(el => el.id_company)) {
          return 'Companhias'
        }
        if(array.some(el => el.id_store)) {
          return 'Lojas'
        }
      }
    }

    const handlePrintTrainingGroups2 = (obj: ITrainingData, feedback = "--") => obj.competitor_trainings_count ? obj.competitor_trainings_count : feedback

    /** Handle Filter Modal */
    function handleInitFilterModal() {
      if(!companyStore.getId) {
        filterMainStore.hideModal()
        filterMainStore.showModal()
      }
    }

    /** Handle Open Media Modal */
    async function handleMediaModal(URL: string) {
      mediaModal.value.URL = URL
      mediaModal.value.toggle = !mediaModal.value.toggle
    }

    /** Handle Open Competitor List Modal */
    const handlePassedCompetitorsModal = (training: ITrainingData) => {
      trainingInfo.value = training
      competitorListModalType.value = STATUS_OPTIONS.PASSED
      competitorModalToggle.value = !competitorModalToggle.value
    }

    const handleFailedCompetitorsModal = (training: ITrainingData) => {
      trainingInfo.value = training
      competitorListModalType.value = STATUS_OPTIONS.FAILED
      competitorModalToggle.value = !competitorModalToggle.value
    }

    /** Handle Open Report Card Modal */
    const handleReportCardModal = (value: ICompetitorTraining) => {
      reportCardModalToggle.value = !reportCardModalToggle.value
      competitorTrainingInfo.value = value
    }

    // Life Cycles
    onMounted(() => {
      getTrainingList()
      handleInitFilterModal()
    })

    watch(() => { companyStore.getId, activeStatus.value }, () => getTrainingList(), { deep: true })

    watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword())

    return {
      isLoading,
      activeStatus,
      keywordSearch,
      companyStore,
      trainingList,
      trainingListRender,
      handleFilterObjectsByKeyword,
      moment,
      mediaModal,
      handleMediaModal,
      handlePrintPoints,
      handlePrintPointsStyle,
      handlePrintTrainingGroups1,
      handlePrintTrainingGroups2,
      handlePassedCompetitorsModal,
      handleFailedCompetitorsModal,
      competitorModalToggle,
      competitorListModalType,
      handleReportCardModal,
      reportCardModalToggle,
      trainingInfo,
      competitorTrainingInfo
    }
  }
})
