
import { defineComponent, onMounted, ref } from 'vue';


export default defineComponent({
name: "SkeletonComponent",
props: {
    rows: {
        type: Number,
        default: 1,
      },
      padding: {
        type: Number,
        default: 1
      }
},
setup(props) {
  return {
  }
},
});
